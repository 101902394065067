<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import SectionHeader from './SectionHeader.vue';

const headerLoaded = ref<boolean>(false);
const imgLoaded = ref<boolean>(false);

const emit = defineEmits(['loaded']);

watchEffect(() => {
    if (headerLoaded.value && imgLoaded.value) {
        emit('loaded');
    }
});
</script>
<template>
    <div>
        <SectionHeader id="offer" text="Mein Angebot für dich" @loaded="headerLoaded = true" />
        <div class="container">
            <div class="text">
                <p class="heading">Für Mamas:</p>
                <ul class="list">
                    <li>Energetisches Gleichgewicht im Energiefeld herstellen</li>
                    <li>Mehr Ausgeglichenheit in den Lebensgrundbereichen Beziehungen,
                        Beruf und Gesundheit schaffen
                    </li>
                    <li>Unterstützung bei der Formulierung und Umsetzung neuer erfüllender Ziele</li>
                    <li>Alte Muster und Strukturen die nicht mehr dienlich sind auflösen</li>
                    <li>Erdung auf allen Ebenen stärken</li>
                    <li>u.v.m.</li>
                </ul>
                <p>Gemeinsam finden wir heraus was DU brauchst, damit du in deine volle LEBENSKRAFT kommst.</p>
                <br>
                <p class="heading">Für Kinder:</p>
                <ul class="list">
                    <li>Energetisches Gleichgewicht im Energiefeld herstellen</li>
                    <li>Lernblockaden lösen und Konzentration verbessern</li>
                    <li>Selbstausdruck fördern und Selbstwert stärken</li>
                    <li>Erdung auf allen Ebenen stärken</li>
                    <li>u.v.m.</li>
                </ul>
                <p>Lerne DICH kennen und lass dich von dir und dem Leben überraschen. Es stecken viele Begabungen in
                    dir. </p>
            </div>
            <div class="image">
                <div class="imageRelativeContainer">
                    <img src="../assets/angebot.jpg"
                        alt="Doris Böck behandelt einen Person die auf einer Liege sitzt während sie hinter der Person steht."
                        @load="imgLoaded = true" />
                    <div class="blob image1" />
                    <div class="blob image2" />
                    <div class="blob image3" />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.blob {
    background-color: #dfcdbf;
}

.blob.image1 {
    left: -40px;
    top: 215px;
}

.blob.image2 {
    left: 70px;
    top: -15px;
}

.blob.image3 {
    bottom: -70px;
    right: -10px;
}

li {
    padding-top: 10px;
}

.image.wider {
    width: 400px;
    align-content: center;
}

.image.wider img {
    max-width: 400px;
}

.text .heading {
    font-weight: bold;
}

.text {
    flex-grow: 1;
}

@media screen and (max-width: 850px) {
    .image.wider {
        width: 100% !important;
        text-align: center;
        margin-top: 18px;
    }

    .image.wider img {
        width: 100% !important;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .blob.image3 {
        bottom: -50px;
    }
}
</style>
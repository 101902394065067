<script setup lang="ts">
import PageHeader from "./components/PageHeader.vue";
import PageMenu from "./components/PageMenu.vue";
import AboutMe from './components/AboutMe.vue';
import MyWork from './components/MyWork.vue';
import ContactSection from "./components/ContactSection.vue";
import FooterSection from "./components/FooterSection.vue";
import MyOffering from "./components/MyOffering.vue";
import LoadingDots from "./components/LoadingDots.vue";
import { computed, ref } from "vue";

const headerLoaded = ref<boolean>(false);
const aboutMeLoaded = ref<boolean>(false);
const myOfferingLoaded = ref<boolean>(false);
const myWorkLoaded = ref<boolean>(false);
const contactLoaded = ref<boolean>(false);

const finishedLoading = computed(() => headerLoaded.value && aboutMeLoaded.value && myOfferingLoaded.value && myWorkLoaded.value && contactLoaded.value);

</script>
<template>
  <div class="loadingContainer" v-show="!finishedLoading">
    <LoadingDots class="loadingDots" />
  </div>
  <div v-show="finishedLoading" class="appContainer">
    <PageMenu />
    <div style="max-height: calc(100dvh - 56px); overflow-y: auto; overflow-x: hidden; max-width: 100vw;">
      <PageHeader @loaded="headerLoaded = true" />
      <AboutMe @loaded="aboutMeLoaded = true" />
      <MyOffering @loaded="myOfferingLoaded = true" />
      <MyWork @loaded="myWorkLoaded = true" />
      <ContactSection @loaded="contactLoaded = true" />
      <FooterSection />
    </div>
  </div>
</template>

<style>
#app {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
}

.container {
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

.image {
  width: 260px;
  align-content: center;
}

.imageRelativeContainer {
  width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.image img {
  max-width: 260px;
}

p {
  margin: 0;
  line-height: 24px;
}

@media screen and (max-width: 850px) {
  .container {
    display: block;
    padding-left: 24px;
    padding-right: 24px;
  }

  .text {
    width: 100%;
  }

  .image,
  .image.wider {
    width: 100% !important;
    text-align: center;
    margin-top: 18px;
  }

  .image img {
    width: 100%;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

.blob {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  z-index: -5;
}
</style>
<style scoped>
.appContainer {
  padding-top: 56px;
}

.loadingContainer {
  width: 100vw;
  height: 100dvh;
  position: relative;
  background-color: #f1f2f6;
}

.loadingDots {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
}
</style>
